(function(wnd){
    function ContactForm(container, params) {
        try {
            if (!container) {
                throw "Container is required!";
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ContactForm.prototype = {
        container: undefined,
        form: undefined,
        notyf: undefined,
        sendedForm: false,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.form = this.container.find('form');
            this.notyf = new Notyf({
                delay: 5000
            });
            this.button = this.form.find('[data-purpose="send-button"]');
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.button && this.button.length > 0) {
                this.button.click(function() {
                    _self.sendForm();
                });
            }

            this.form.submit(function(e) {
                e.preventDefault();

                _self.sendForm();
            });
        },
        setParams: function() {

        },
        sendForm: function() {
            var _self = this;
            if (!this.sendedForm) {
                this.sendedForm = true;
                
                $.ajax({
                    url:ajax_controller+'/contact',
                    dataType:'json',
                    type:'post',
                    data:_self.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (typeof gtag === 'function') {
                            var label = !response.success && typeof response.errors !== 'undefined' ? JSON.stringify(response.errors) : undefined;
                            var data = {
                                'event_category':'Lead',
                                'event_action':(response.success ? 'Sikeres' : 'Sikertelen')+'  ajánlatkérés',
                            };

                            if (label) {
                                data.event_label = label;
                            }
                            gtag('event', 'Rendelés', data);
                        }
                        if (response.success) {
                            if (response.message && response.message.trim() !== '') {
                                _self.notyf.confirm(response.message)
                            }

                            _self.confirmForm();
                        } else {
                            if (response.message && response.message.trim() !== '') {
                                _self.notyf.alert(response.message)
                            }

                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(key, message) {
                                    var input = _self.form.find('[name="'+key+'"]');
                                    if (input && input.length > 0) {
                                        input.addClass('has-error');
                                    }
                                    if (message.trim() !== '') {
                                        _self.notyf.alert(message);
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 300);
                    }
                });
            }
        },
        confirmForm: function() {
            this.container.addClass('success-send');
            this.form.find('.field').each(function(){
                $(this).val('');
            });
            this.form.find('input[type="checkbox"]').prop('checked', false);
        }
    };

    wnd.ContactForm = ContactForm;
})(window);
