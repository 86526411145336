(function(){
    function PWGalleryWindow(parent) {
        try {
            this.parent = parent;

            this.init();
        } catch (error) {
            
        }
    }

    PWGalleryWindow.prototype = {
        container: undefined,
        galleryContainer: undefined,
        overlay: undefined,
        parent: undefined,
        thumbnailContainer: undefined,
        sliderContainer: undefined,
        sliderList: undefined,
        imageList: undefined,
        slider: undefined,
        closeBtn: undefined,
        singleImageGallery: true,
        init: function() {
            this.setElements();
            this.createThumbnailList();
            this.createSliderContent();
            this.addDOMEvents();
        },
        setElements: function() {
            this.container = $('<div>', {
                class: 'pw-gallery-container',
            });
            this.overlay = $('<div>', {
                class: 'pw-gallery-overlay',
            });

            this.galleryContainer = $('<div>', {
                class: 'pw-gallery-window'
            });

            this.sliderContainer = $('<div>', {
                class:'pw-gallery-slider-container swiper-container'
            });

            if (this.parent.images.length > 1) {
                this.singleImageGallery = false;

                this.thumbnailContainer = $('<div>', {
                    class:'thumbnail-container'
                });
                this.galleryContainer.append(this.thumbnailContainer);
            } else {
                this.sliderContainer.addClass('single-image-gallery');
            }

            this.closeBtn = $('<span>', {
                class:'close-btn cursor-pointer',
                html:svg('close-icon')
            });
            this.galleryContainer.append(this.closeBtn);
            this.galleryContainer.append(this.sliderContainer);

            this.container.append(this.overlay);
            this.container.append(this.galleryContainer);

            $('body').append(this.container);
        },
        addDOMEvents: function() {
            var _self = this;

            this.overlay.click(function() {
                _self.close();
            });

            if (!this.singleImageGallery) {
                this.thumbnailContainer.find('.thumbnail-item').click(function(){
                    _self.slider.slideTo($(this).data('index'));
                });
            }

            if (this.closeBtn && this.closeBtn.length > 0) {
                this.closeBtn.click(function(){
                    _self.close();
                });
            }
        },
        open: function() {
            $('body').addClass('overflow-hidden opened-pw-gallery');
            $('html').addClass('overflow-hidden');
            
            if (!this.singleImageGallery) {
                this.initSlider()
            }
        },
        close: function() {
            $('body').removeClass('overflow-hidden opened-pw-gallery');
            $('html').removeClass('overflow-hidden');
            this.stopVideos();
            if (!this.singleImageGallery) {
                this.destroySlider();
            }
        },
        stopVideos: function() {
            this.sliderContainer.find('video').each(function(){
                $(this).trigger('pause');
            });
        },
        initSlider: function() {
            var _self = this;
            this.slider = new Swiper('.pw-gallery-slider-container', {
                initialSlide: this.parent.currentIndex,
                pagination: {
                    el:'.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
            this.markActiveThumbItem(this.parent.currentIndex);
            this.slider.on('transitionEnd', function(){
                _self.stopVideos();
                var item = _self.sliderContainer.find('.swiper-slide-active');
                if (item) {
                    var index = $(item).data('index');
                    _self.markActiveThumbItem(index);
                }
            });
        },
        markActiveThumbItem: function(index) {
            this.thumbnailContainer.find('.thumbnail-item').removeClass('active');
            this.thumbnailContainer.find('.window-thumbnail-item[data-index="'+index+'"]').addClass('active');
        },
        destroySlider: function() {
            this.slider.destroy();
        },
        createSliderContent: function() {
            this.sliderList = $('<ul>', {
                class:'pw-gallery-slider-list swiper-wrapper without-default-style',
            });

            for (var i = 0; i < this.parent.images.length; i++) {
                var img = this.parent.images[i];
                var item = $('<li>', {
                    class:'pw-gallery-slider-item swiper-slide display-flex justify-center align-items-center',
                    'data-index':i
                });

                if (typeof img.is_video !== 'undefined' && img.is_video) {
                    item.append(this.parent.getVideoContent(img));
                } else {
                    item.append('<img src="'+img.urls.big+'" alt="'+img.name+'" />');
                }

                this.sliderList.append(item);
            }

            this.sliderContainer.append(this.sliderList);
            if (this.parent.images.length > 1) {
                this.sliderContainer.append('<div class="swiper-pagination display-flex justify-center align-items-center zero-text"></div>');
            }
        },
        createThumbnailList: function() {
            for (var i = 0; i < this.parent.images.length; i++) {
                var img = this.parent.images[i];
                var item = $('<div>', {
                    class:'thumbnail-item window-thumbnail-item display-flex justify-center align-items-center cursor-pointer position-relative'+(i == this.parent.currentIndex ? ' active' : ''),
                    'data-index':i
                });

                if (typeof img.is_video !== 'undefined' && img.is_video) {
                    var poster = typeof img.poster !== 'undefined' ? img.poster : undefined;
                    if (poster) {
                        var src = typeof poster.urls.small !== 'undefined' ? poster.urls.small : poster.urls.big;
                        item.append('<img src="'+src+'" alt="'+img.name+'" />');
                    }

                    item.append('<span class="play-button cursor-pointer position-absolute">'+svg('play-icon')+'</span>');
                } else {
                    var src = typeof img.urls.small !== 'undefined' ? img.urls.small : img.urls.big;
                    item.append('<img src="'+src+'" alt="'+img.name+'" />');
                }

                this.thumbnailContainer.append(item);
            }
        }
    };

    function PWGallery(params) {
        try {
            if (params) {
                this.setParams(params);
            }
            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    PWGallery.prototype = {
        previewContainer: undefined,
        thumbnailContainer: undefined,
        galleryList: undefined,
        gallerySlider: undefined,
        thumbnailList: undefined,
        thumbnailSlider: undefined,
        currentIndex: 0,
        windowWidth: 0,
        images: [],
        options: {
            breakpoint: 600
        },
        opened: false,
        galleryWindow: undefined,
        overlay: undefined,
        mode: undefined,
        initialized: false,
        selectors: {
            previewContainer: '[data-purpose="preview-container"]',
            thumbnailContainer: '[data-purpose="thumbnail-container"]',
            thumbnailList: '[data-purpose="thumbnail-list"]'
        },
        init: function() {
            this.windowWidth = window.innerWidth;
            this.setElements();
            this.addDOMEvents();
            this.checkThumbnailSlider();

            this.initialized = true;
        },
        setElements: function() {
            this.previewContainer = $(this.getSelector('previewContainer'));
            this.thumbnailContainer = $(this.getSelector('thumbnailContainer'));
            this.thumbnailList = $(this.getSelector('thumbnailList'));
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.previewContainer && this.previewContainer.length == 1) {
                this.previewContainer.click(function(){
                    _self.openGallery();
                });
            }

            $(document).on('pwstudio.window_width_changed', function(e, newWidth) {
                _self.windowWidth = newWidth;
                _self.checkThumbnailSlider();
            });
        },
        addThumbnailDOMEvents: function() {
            var _self = this;

            this.thumbnailList.find('.thumbnail-item').click(function() {
                _self.showPreview($(this).data('index'));
                _self.thumbnailList.find('.thumbnail-item').removeClass('active');
                $(this).addClass('active');
            });
        },
        removeThumbnailDOMEvents: function() {
            if (this.mode == 'mobile') {
                this.thumbnailSlider.destroy();
            } else {
                this.thumbnailList.find('.thumbnail-item').off('click');
            }
            this.thumbnailList.html('');
        },
        showPreview: function(index) {
            if (parseInt(index) != this.currentIndex) {
                var img = typeof this.images[index] !== 'undefined' ? this.images[index] : undefined;
                if (img) {
                    this.currentIndex = parseInt(index);

                    if (typeof img.is_video !== 'undefined' && img.is_video) {
                        this.previewContainer.html('');
                        this.previewContainer.append(this.getVideoContent(img));
                    } else {
                        this.previewContainer.html('<img src="'+img.urls.big+'" alt="'+img.name+'" />');
                    }
                }
            }
        },
        openGallery: function() {
            if (!this.galleryWindow) {
                this.galleryWindow = new PWGalleryWindow(this);
            }
            this.stopVideos(this.previewContainer);
            this.galleryWindow.open();
        },
        closeGallery: function() {
            if (this.galleryWindow) {
                this.galleryWindow.close();
            }
        },
        checkThumbnailSlider: function() {
            var breakpoint = this.getOption('breakpoint');
            var slider = breakpoint && parseInt(breakpoint) >= this.windowWidth;
            var mode = slider ? 'mobile' : 'desktop';

            if (mode != this.mode) {
                this.createThumbnailList(slider);
                this.mode = mode;
            }
        },
        createThumbnailList: function(slider) {
            
            if (this.initialized) {
                this.removeThumbnailDOMEvents();
            }

            for (var i = 0; i < this.images.length; i++) {
                var img = this.images[i];

                var item = $('<li>', {
                    class:'thumbnail-item display-flex swiper-slide justify-center align-items-center cursor-pointer position-relative'+(i == 0 && !slider ? ' active' : ''),
                    'data-index': i,
                });

                if (typeof img.is_video !== 'undefined' && img.is_video) {
                    if (slider) {
                        item.append(this.getVideoContent(img));
                    } else {
                        var poster = typeof img.poster !== 'undefined' ? img.poster : undefined;
                        if (poster) {
                            var src = typeof poster.urls.small !== 'undefined' ? poster.urls.small : poster.urls.big;
                            item.append('<img '+(!this.initialized ? 'class="lazy" data-' : '')+'src="'+src+'" alt="'+img.name+'" />');
                        }

                        item.append('<span class="play-button cursor-pointer position-absolute">'+svg('play-icon')+'</span>');
                    }
                } else {
                    var src = typeof img.urls.small !== 'undefined' && !slider ? img.urls.small : img.urls.big;
                    item.append('<img '+(!this.init ? 'class="lazy" data-' : '')+'src="'+src+'" alt="'+img.name+'"  />');
                }

                this.thumbnailList.append(item);
            }

            if (!slider) {
                this.addThumbnailDOMEvents();
            } else {
                var _self = this;

                this.thumbnailSlider = new Swiper('.mobile-thumbnail-slider', {
                    pagination: {
                        el:'.swiper-pagination',
                        clickable: true
                    }
                });

                this.thumbnailSlider.on('transitionEnd', function(){
                    _self.stopVideos(_self.thumbnailContainer);
                });
            }
        },
        setParams: function(params) {
            if (params.images) this.setImages(params.images);
        },
        setImages: function(images) {
            if (Array.isArray(images)) {
                this.images = images;
            }
        },
        getSelector: function(key) {
            if (typeof this.selectors[key] === 'undefined') {
                throw 'Undefined selector: '+key;
            }

            return this.selectors[key];
        },
        getVideoContent: function(img) {
            var poster = typeof img.poster !== 'undefined' ? img.poster : undefined;
                        
            return $('<video>', {
                controls:true,
                poster:poster ? poster.urls.big : '',
                html: $('<source>', {
                    src: img.urls.original,
                    type: img.mime
                })
            }); 
        },
        getOption: function(key) {
            if (typeof this.options[key] === 'undefined') {
                throw 'Undefined option: '+key;
            }

            return this.options[key];
        },
        stopVideos: function(container) {
            container.find('video').each(function(){
                $(this).trigger('pause');
            });
        }
    };

    window.PWGallery = PWGallery;
});
