(function(wnd) {
    function BosisioMap(params) {
        try {
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error)
        }
    }
    []

    BosisioMap.prototype = {
        map: undefined,
        markers: [],
        markerDatas: [],
        markerCluster: undefined,
        markerClusterIcons: [{
            url: 'assets/img/markers/counter-circles-small.png',
            height: 20,
            width: 20,
            anchor: [12, 0],
            textColor: '#ffffff',
            textSize: 20,
        }, {
            url: 'assets/img/markers/counter-circles-medium.png',
            height: 31,
            width: 31,
            anchor: [16, 0],
            textColor: '#ffffff',
            textSize: 24
        }, {
            url: 'assets/img/markers/counter-circles-big.png',
            height: 47,
            width: 47,
            anchor: [32, 0],
            textColor: '#ffffff',
            textSize: 26
        }],
        markerBounds: undefined,
        initialized: false,
        init: function () {
            this.addDOMEvents();
            this.initMap();
        },
        addDOMEvents: function () {
            var _self = this;

            $(document).on('bosisio.google_maps_loaded', function () {
                _self.initMap();
            });
        },
        setParams: function (params) {
            if (params.markerDatas) this.setMarkerDatas(params.markerDatas);

        },
        setMarkerDatas: function (markers) {
            if (!Array.isArray(markers)) {
                throw 'MarkerDatas must be an array!';
            }
            
            this.markerDatas = markers;
        },
        getPosition: function(key) {
            if (key) {
                return typeof this.position[key] != undefined ? this.position[key] : '';
            }

            return this.position;
        },
        initMap: function () {
            if (typeof google === 'undefined' || this.initialized) {
                return;
            }

            var defaultZoom = 7;
            var mobileZoom = 6;
            var centerSmall = {lat: 48.9621484, lng: 19.4576346};
            var centerLarge = {lat: 46.8900835, lng: 16.9624377};

            this.map = new google.maps.Map(document.getElementById('bosisioMap'), {
                zoom: window.innerWidth < 1023 ? mobileZoom : defaultZoom,
                center: window.innerWidth < 1023 ? centerSmall : centerLarge,
                fullscreenControl: false,
                mapTypeControl: false,
                scrollwheel: false,
                draggable: true,
                disableDoubleClickZoom: false,
                panControl: false,
                streetViewControl: false,
                zoomControl: true,
                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "saturation": -10
                            },
                            {
                                "lightness": 30
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "saturation": -60
                            },
                            {
                                "lightness": 10
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "saturation": -60
                            },
                            {
                                "lightness": 60
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 60
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 60
                            }
                        ]
                    }
                ]
            });

            this.initialized = true;
            this.initMarkers();
        },
        initMarkers: function () {
            if (this.markerDatas.length === 0) {
                return;
            }

            var markerBounds = new google.maps.LatLngBounds();

            for (var i = 0; i < this.markerDatas.length; i++) {
                var markerData = this.markerDatas[i];

                if (typeof markerData.lat === 'undefined' || typeof markerData.lng === 'undefined') {
                    continue;
                }

                var latLng = new google.maps.LatLng(parseFloat(markerData.lat), parseFloat(markerData.lng));
                markerBounds.extend(latLng);

                var marker = new google.maps.Marker({
                    position: {
                        lat: parseFloat(markerData.lat),
                        lng: parseFloat(markerData.lng)
                    },
                    map: this.map,
                    title: markerData.title,
                    icon: {
                        url: 'assets/img/svg/marker.svg',
                        scaledSize: new google.maps.Size(24, 24),
                    },
                    my_pos: {
                        lat: parseFloat(markerData.lat),
                        lng: parseFloat(markerData.lng)
                    }
                });

                this.markers.push(marker)
            }

            var _self = this;
            _self.initMarkerCluster();
        },
        initMarkerCluster: function () {
            var options = {
                'styles': this.markerClusterIcons,
            };
        
            this.markerCluster = new markerClusterer.MarkerClusterer({
                markers: this.markers,
                map: this.map,
                renderer: {
                    render: function (cluster, stats, map) {
                        var counter = cluster.markers.length;
                        var size = 'small';
                        var fontSize = '12px';

                        if (counter > 7) {
                            size = 'big';
                            fontSize = '16px';
                        } else if (counter > 4) {
                            size = 'medium';
                            fontSize = '14px';
                        }

                        var icon = base_url + 'assets/img/markers/counter-circles-'+size+'.svg';

                        return new google.maps.Marker({
                            position: cluster._position,
                            icon: icon,
                            /*label: {
                                text: String(counter),
                                color: 'white',
                                fontSize: fontSize
                            }*/
                        });
                    }
                }
            });
        },
        createMarkerBounds: function() {
            this.markerBounds = new google.maps.LatLngBounds();
        },
    }

    wnd.BosisioMap = BosisioMap;
})(window);